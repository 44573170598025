import COLORS from "~/lib/helpers/color-helper";

interface Props {
  fillColor?: string;
  width?: number;
  height?: number;
}
export default function EditFilled2({ fillColor = COLORS.copyDark, width = 24, height = 24 }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 16.8538V19.5556C4 19.8045 4.19553 20 4.44438 20H7.14623C7.26177 20 7.37731 19.9556 7.4573 19.8667L17.1626 10.1703L13.8297 6.83738L4.13331 16.5338C4.04444 16.6227 4 16.7293 4 16.8538ZM19.74 7.59283C20.0867 7.24622 20.0867 6.68629 19.74 6.33967L17.6603 4.25996C17.3137 3.91335 16.7538 3.91335 16.4072 4.25996L14.7807 5.8864L18.1136 9.21927L19.74 7.59283Z" fill={fillColor} />
    </svg>
  );
}
