import React from 'react';

export default function OpenBookIcon() {
  return (
    <svg viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_5327_96928)'>
        <rect width='64' height='64' rx='32' fill='#3B61EB' fillOpacity='0.1' />
        <path
          opacity='0.5'
          d='M41.7143 11.4286C45.817 11.4286 49.1429 8.10267 49.1429 3.99999C49.1429 -0.102701 45.817 -3.42859 41.7143 -3.42859C37.6116 -3.42859 34.2858 -0.102701 34.2858 3.99999C34.2858 8.10267 37.6116 11.4286 41.7143 11.4286Z'
          fill='url(#paint0_linear_5327_96928)'
        />
        <path
          opacity='0.9'
          d='M8.00179 24.3836C10.0488 31.6636 11.4307 39.1307 12.1316 46.6616C19.1248 48.2983 26.2495 50.9029 31.9723 55.3871C31.9723 48.4019 31.9723 31.9503 31.9723 31.9503C31.9723 31.9503 28.0973 28.7285 22.948 26.9006C18.1571 25.2 13.0834 24.2323 7.9978 24.3836H8.00179Z'
          fill='#3B61EB'
        />
        <path
          opacity='0.9'
          d='M8.00179 24.3836C10.0488 31.6636 11.4307 39.1307 12.1316 46.6616C19.1248 48.2983 26.2495 50.9029 31.9723 55.3871C31.9723 48.4019 31.9723 31.9503 31.9723 31.9503C31.9723 31.9503 28.0973 28.7285 22.948 26.9006C18.1571 25.2 13.0834 24.2323 7.9978 24.3836H8.00179Z'
          fill='url(#paint1_linear_5327_96928)'
        />
        <path
          d='M31.9764 31.2326C31.9764 31.2326 24.9473 24.0084 10.1405 23.4309L9.56702 20.9976C9.56702 20.9976 26.8549 22.0291 31.9764 31.2326Z'
          fill='#F2AC13'
        />
        <path
          d='M31.9774 29.9505C31.9774 29.9505 26.2745 21.4718 11.6111 19.9943L11.1451 17.6606C11.1451 17.6606 25.1475 18.9828 31.9814 29.9505H31.9774Z'
          fill='url(#paint2_linear_5327_96928)'
        />
        <path
          d='M55.9459 24.3837C53.8989 31.6636 52.517 39.1308 51.8161 46.6616C44.8229 48.2984 37.6983 50.9029 31.9755 55.3872C31.9755 48.402 31.9755 31.9504 31.9755 31.9504C31.9755 31.9504 35.8504 28.7286 40.9997 26.9006C45.7906 25.2001 50.8643 24.2324 55.9499 24.3837H55.9459Z'
          fill='#3B61EB'
        />
        <path
          d='M31.9755 31.2326C31.9755 31.2326 39.0005 24.0084 53.8113 23.431L54.3848 20.9977C54.3848 20.9977 37.0969 22.0291 31.9755 31.2326Z'
          fill='#F2AC13'
        />
        <path
          d='M31.9749 29.9505C31.9749 29.9505 37.6778 21.4718 52.3413 19.9943L52.8072 17.6606C52.8072 17.6606 38.8049 18.9828 31.9709 29.9505H31.9749Z'
          fill='url(#paint3_linear_5327_96928)'
        />
        <path
          d='M14.7716 56.6897C13.8927 56.6897 13.1786 55.9756 13.1786 55.0967C13.1786 54.2178 13.8927 53.5037 14.7716 53.5037C15.6505 53.5037 16.3646 54.2178 16.3646 55.0967C16.3646 55.9756 15.6505 56.6897 14.7716 56.6897ZM14.7716 53.9399C14.135 53.9399 13.6148 54.4602 13.6148 55.0967C13.6148 55.7333 14.135 56.2535 14.7716 56.2535C15.4081 56.2535 15.9283 55.7333 15.9283 55.0967C15.9283 54.4602 15.4081 53.9399 14.7716 53.9399Z'
          fill='#3B61EB'
        />
        <path
          opacity='0.5'
          d='M22.5012 42.679C21.0915 42.679 19.9485 41.5321 19.9485 40.1262C19.9485 38.7204 21.0954 37.5735 22.5012 37.5735C23.9071 37.5735 25.054 38.7204 25.054 40.1262C25.054 41.5321 23.9071 42.679 22.5012 42.679ZM22.5012 38.1071C21.3901 38.1071 20.4861 39.0112 20.4861 40.1223C20.4861 41.2334 21.3901 42.1374 22.5012 42.1374C23.6124 42.1374 24.5164 41.2334 24.5164 40.1223C24.5164 39.0112 23.6124 38.1071 22.5012 38.1071Z'
          fill='url(#paint4_linear_5327_96928)'
        />
        <path
          d='M17.8731 24.6385C17.3156 24.6385 16.8616 24.1844 16.8616 23.6269C16.8616 23.0694 17.3156 22.6154 17.8731 22.6154C18.4307 22.6154 18.8847 23.0694 18.8847 23.6269C18.8847 24.1844 18.4307 24.6385 17.8731 24.6385ZM17.8731 23.157C17.6103 23.157 17.3992 23.368 17.3992 23.6309C17.3992 23.8937 17.6103 24.1048 17.8731 24.1048C18.136 24.1048 18.347 23.8937 18.347 23.6309C18.347 23.368 18.136 23.157 17.8731 23.157Z'
          fill='#F2AC13'
        />
        <path
          d='M36.531 17.1707L35.5075 16.7326C35.3602 16.6689 35.2566 16.5415 35.2248 16.3861L35.0057 15.2949C34.9261 14.9047 34.4323 14.7732 34.1694 15.0719L33.4366 15.9082C33.3331 16.0277 33.1738 16.0875 33.0185 16.0715L31.9114 15.9441C31.5131 15.8963 31.2383 16.3264 31.4414 16.6689L32.0109 17.6247C32.0906 17.7601 32.1025 17.9274 32.0348 18.0707L31.5689 19.0823C31.4016 19.4447 31.7242 19.8429 32.1145 19.7513L33.2017 19.5044C33.357 19.4686 33.5203 19.5124 33.6358 19.6199L34.4562 20.3726C34.7509 20.6434 35.2248 20.4602 35.2646 20.062L35.3642 18.9548C35.3801 18.7955 35.4677 18.6561 35.6071 18.5765L36.5788 18.0309C36.9253 17.8358 36.8974 17.326 36.531 17.1667V17.1707Z'
          fill='#F2AC13'
        />
        <path
          opacity='0.3'
          d='M47.4082 34.6584L46.3847 34.2203C46.2374 34.1566 46.1338 34.0291 46.102 33.8738L45.8829 32.7826C45.8033 32.3923 45.3095 32.2609 45.0466 32.5596L44.3138 33.3959C44.2103 33.5154 44.051 33.5751 43.8957 33.5592L42.7886 33.4318C42.3903 33.384 42.1155 33.8141 42.3186 34.1566L42.8881 35.1124C42.9678 35.2478 42.9797 35.415 42.912 35.5584L42.4461 36.5699C42.2788 36.9323 42.6014 37.3306 42.9917 37.239L44.0789 36.9921C44.2342 36.9562 44.3975 37 44.513 37.1076L45.3333 37.8603C45.6281 38.1311 46.102 37.9479 46.1418 37.5496L46.2414 36.4425C46.2573 36.2832 46.3449 36.1438 46.4843 36.0642L47.456 35.5186C47.8025 35.3234 47.7746 34.8137 47.4082 34.6544V34.6584Z'
          fill='url(#paint5_linear_5327_96928)'
        />
        <path
          d='M23.933 9.38502L22.344 9.84698C22.117 9.91468 21.874 9.86291 21.6908 9.71158L20.4045 8.66419C19.9425 8.28984 19.2536 8.60047 19.2336 9.19784L19.1819 10.8545C19.1739 11.0895 19.0505 11.3085 18.8513 11.436L17.4575 12.332C16.9557 12.6546 17.0433 13.4073 17.6008 13.6104L19.158 14.1719C19.381 14.2516 19.5483 14.4348 19.608 14.6658L20.0301 16.2667C20.1815 16.8402 20.9262 16.9955 21.2886 16.5256L22.3041 15.2153C22.4475 15.0282 22.6745 14.9246 22.9134 14.9406L24.5662 15.0361C25.1596 15.072 25.5339 14.4109 25.1994 13.9171L24.2675 12.5471C24.1361 12.352 24.1082 12.105 24.1918 11.886L24.7932 10.3408C25.0082 9.78725 24.4945 9.22572 23.925 9.39298L23.933 9.38502Z'
          fill='url(#paint6_linear_5327_96928)'
        />
        <path
          d='M50.2333 55.9632L49.5891 56.0863C49.4989 56.1043 49.4024 56.0773 49.3371 56.0143L48.8672 55.573C48.6992 55.4139 48.416 55.51 48.388 55.7351L48.3102 56.3625C48.2978 56.4525 48.2417 56.5306 48.1608 56.5726L47.5789 56.8668C47.3705 56.9719 47.3767 57.263 47.5914 57.3591L48.1857 57.6263C48.2698 57.6653 48.332 57.7403 48.3475 57.8274L48.4565 58.4518C48.4969 58.6769 48.7832 58.758 48.945 58.5929L49.39 58.1306C49.4522 58.0645 49.5456 58.0315 49.6389 58.0465L50.2862 58.1396C50.5195 58.1726 50.6907 57.9325 50.5756 57.7343L50.255 57.182C50.2084 57.104 50.2084 57.0079 50.2488 56.9268L50.5382 56.3595C50.644 56.1554 50.4604 55.9242 50.2302 55.9693L50.2333 55.9632Z'
          fill='url(#paint7_linear_5327_96928)'
        />
        <path
          d='M32.7131 8.17419L32.069 8.29726C31.9788 8.31527 31.8823 8.28826 31.8169 8.22522L31.3471 7.78395C31.179 7.62485 30.8959 7.72091 30.8679 7.94605L30.7901 8.57343C30.7776 8.66349 30.7216 8.74154 30.6407 8.78356L30.0588 9.07774C29.8503 9.18281 29.8565 9.47399 30.0713 9.57004L30.6656 9.83721C30.7496 9.87623 30.8118 9.95128 30.8274 10.0383L30.9363 10.6627C30.9768 10.8879 31.263 10.9689 31.4249 10.8038L31.8698 10.3415C31.9321 10.2755 32.0254 10.2425 32.1188 10.2575L32.766 10.3505C32.9994 10.3835 33.1706 10.1434 33.0554 9.94527L32.7349 9.39294C32.6882 9.31489 32.6882 9.21883 32.7287 9.13778L33.0181 8.57043C33.1239 8.36631 32.9403 8.13516 32.71 8.18019L32.7131 8.17419Z'
          fill='#3B61EB'
        />
        <path
          d='M51.6923 16.9406L51.2127 16.6474C51.1449 16.6053 51.1021 16.5333 51.0968 16.4543L51.07 15.8995C51.0611 15.6993 50.824 15.5975 50.6688 15.7274L50.2374 16.0856C50.1749 16.1365 50.0929 16.1541 50.0145 16.133L49.4707 15.9855C49.2745 15.9328 49.1034 16.1242 49.18 16.3086L49.3922 16.823C49.4225 16.8967 49.4136 16.9793 49.3708 17.046L49.0606 17.5112C48.95 17.678 49.0802 17.8975 49.2817 17.8835L49.8451 17.8431C49.9253 17.8378 50.0038 17.8712 50.0537 17.9326L50.4067 18.3663C50.5333 18.5225 50.7865 18.4681 50.8347 18.2732L50.9702 17.7342C50.9898 17.657 51.0468 17.5955 51.1217 17.5674L51.6495 17.3708C51.8385 17.3006 51.8652 17.046 51.6923 16.9406Z'
          fill='url(#paint8_linear_5327_96928)'
        />
        <path
          d='M24.9105 55.1878C24.9105 55.4586 24.6914 55.6776 24.4206 55.6776C24.1498 55.6776 23.9308 55.4586 23.9308 55.1878C23.9308 54.917 24.1498 54.6979 24.4206 54.6979C24.6914 54.6979 24.9105 54.917 24.9105 55.1878Z'
          fill='#F2AC13'
        />
        <path
          d='M14.4641 16.3066C14.4641 16.5774 14.245 16.7965 13.9742 16.7965C13.7034 16.7965 13.4844 16.5774 13.4844 16.3066C13.4844 16.0358 13.7034 15.8168 13.9742 15.8168C14.245 15.8168 14.4641 16.0358 14.4641 16.3066Z'
          fill='#E7EEF9'
        />
        <path
          opacity='0.3'
          d='M43.5165 14.905C44.0729 14.905 44.524 14.4539 44.524 13.8975C44.524 13.341 44.0729 12.8899 43.5165 12.8899C42.96 12.8899 42.5089 13.341 42.5089 13.8975C42.5089 14.4539 42.96 14.905 43.5165 14.905Z'
          fill='url(#paint9_linear_5327_96928)'
        />
        <path
          opacity='0.2'
          d='M24.7895 19.1067C25.2206 19.1067 25.57 18.7573 25.57 18.3262C25.57 17.8951 25.2206 17.5456 24.7895 17.5456C24.3584 17.5456 24.0089 17.8951 24.0089 18.3262C24.0089 18.7573 24.3584 19.1067 24.7895 19.1067Z'
          fill='#F2AC13'
        />
        <path
          opacity='0.2'
          d='M48 72C59.3613 72 68.5714 62.7898 68.5714 51.4286C68.5714 40.0673 59.3613 30.8571 48 30.8571C36.6387 30.8571 27.4286 40.0673 27.4286 51.4286C27.4286 62.7898 36.6387 72 48 72Z'
          fill='url(#paint10_linear_5327_96928)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_5327_96928'
          x1='41.7143'
          y1='-3.42859'
          x2='36.0584'
          y2='13.3772'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5327_96928'
          x1='19.985'
          y1='24.3682'
          x2='14.9042'
          y2='39.466'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_5327_96928'
          x1='21.5633'
          y1='17.6606'
          x2='18.6076'
          y2='32.5503'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_5327_96928'
          x1='42.3891'
          y1='17.6606'
          x2='39.4334'
          y2='32.5503'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_5327_96928'
          x1='22.5012'
          y1='37.5735'
          x2='20.5576'
          y2='43.3486'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_5327_96928'
          x1='44.9751'
          y1='32.396'
          x2='42.8021'
          y2='38.6883'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_5327_96928'
          x1='21.2262'
          y1='8.50146'
          x2='18.0345'
          y2='17.8673'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_5327_96928'
          x1='49.0194'
          y1='55.4943'
          x2='47.8065'
          y2='59.0981'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_5327_96928'
          x1='50.414'
          y1='15.6694'
          x2='49.3528'
          y2='18.8228'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_5327_96928'
          x1='43.5165'
          y1='12.8899'
          x2='43.5165'
          y2='14.905'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2AC13' />
          <stop offset='1' stopColor='#F2AC13' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_5327_96928'
          x1='48'
          y1='30.8571'
          x2='32.3375'
          y2='77.3963'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D4DDFA' />
          <stop offset='1' stopColor='#D4DDFA' stopOpacity='0' />
        </linearGradient>
        <clipPath id='clip0_5327_96928'>
          <rect width='64' height='64' rx='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
