import Image from 'next/image';

export default function PangoAvatar({
  size = 'small',
  customSize
}: {
  size?: 'small' | 'med' | 'large';
  customSize?: number;
}) {

  const SMALL_AVATAR = '/images/pango-avatar-thumb.png';
  const LARGE_AVATAR = '/images/pango-avatar-large.png';
  const AVATAR = customSize ? customSize < 60 ? SMALL_AVATAR : LARGE_AVATAR : size === 'small' ? SMALL_AVATAR : LARGE_AVATAR;

  const SMALL_AVATAR_SIZE = 40;
  const MED_AVATAR_SIZE = 80;
  const LARGE_AVATAR_SIZE = 120;
  const AVATAR_SIZE = customSize ? customSize : size === 'small' ? SMALL_AVATAR_SIZE : size === 'med' ? MED_AVATAR_SIZE : LARGE_AVATAR_SIZE;

  return (
    <Image
      src={AVATAR}
      alt="Pango"
      width={AVATAR_SIZE}
      height={AVATAR_SIZE}
    />
  );
}