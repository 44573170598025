import COLORS from "~/lib/helpers/color-helper";

interface Props {
  strokeColor?: string;
  height?: number;
  width?: number;
}

export default function CloseCircle({ strokeColor = COLORS.copyDark, height = 16, width = 16 }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" >
      <g clipPath="url(#clip0_5193_98492)">
        <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" stroke={strokeColor} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 6L6 10" stroke={strokeColor} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 6L10 10" stroke={strokeColor} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5193_98492">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}