import COLORS from "~/lib/helpers/color-helper";

interface Props {
  strokeColor?: string;
  height?: number;
  width?: number;
}

export default function PlusCircle({ strokeColor = COLORS.copyDark, height = 16, width = 16 }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" >
      <g clipPath="url(#clip0_5193_98613)">
        <path d="M7.99992 14.6673C11.6818 14.6673 14.6666 11.6825 14.6666 8.00065C14.6666 4.31875 11.6818 1.33398 7.99992 1.33398C4.31802 1.33398 1.33325 4.31875 1.33325 8.00065C1.33325 11.6825 4.31802 14.6673 7.99992 14.6673Z" stroke={strokeColor} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 5.33398V10.6673" stroke={strokeColor} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.33337 8H10.6667" stroke={strokeColor} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5193_98613">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
