'use client';

import { collectionGroup, getDocs, orderBy, query, where } from 'firebase/firestore';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { pluralize } from 'sdk';
import { BookReview, Title, UserProfile } from 'types';
import { db, InfiniteQueryResponse } from '~/clients/firebase/client';
import COLORS from '~/lib/helpers/color-helper';
import useAuthenticatedAction from '~/lib/hooks/use-authenticated-action';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import styles from '../styles/components/book-reviews.module.scss';
import Button from './button';
import Pill from './pill';
import ReviewEditor from './review-editor';
import SessionForm from './session-form';
import Spacer from './spacer';
import Spinner from './spinner';
import StarRating from './star-rating';
import BookReviewTile from './tiles/book-review';
import OpenBookIcon from '~/assets/svg/icons/open-book';
import PercentBar from './percent-bar';
import classNames from 'classnames';
import { Box } from './layout/box';
import Heading from './typography/heading';
import Text from './typography/text';
import PangoAvatar from './pango-avatar';
import { Flex } from './layout/flex';
import AiIcon from '../assets/svg/icons/ai';
import Link from 'next/link';
import Links from '../lib/helpers/link-helper';
import Firebase from '@/clients/firebase/firebase-react-query';
import ChevronIcon from '~/assets/svg/icons/chevron';
interface BookReviewsProps {
  title?: Title;
  user_id?: string;
  style: 'inline' | 'full';
  type: 'user' | 'book';
  innerRef?: RefObject<HTMLDivElement>;
}
export default function BookReviews({ title, user_id, style, type, innerRef }: BookReviewsProps) {
  const [likedReviewsByPage, setLikedReviewsByPage] = useState<string[][]>([]);
  const [publishedReviews, setPublishedReviews] = useState<boolean>(true);

  const { state, dispatch } = useGlobalState();
  const uid = state.user?.data?.uid;

  const IS_OWNER = uid === user_id;

  const INLINE_REVIEW_LIMIT = 3;
  const FULL_REVIEW_LIMIT = 10;

  const constraints = [];
  let additionalQuery = {
    key: 'user',
    path: 'users',
    name: 'userData',
  };

  if (type === 'book') {
    constraints.push(...[where('title_id', '==', title?.id), where('published', '==', true), orderBy('likes', 'desc')]);
  }

  if (type === 'user' && user_id) {
    additionalQuery = {
      key: 'title_id',
      path: 'titles',
      name: 'titleData',
    };

    constraints.push(where('user', '==', user_id));
    constraints.push(orderBy('timestamp', 'desc'));

    if (!IS_OWNER) {
      constraints.push(where('published', '==', true));
    } else {
      constraints.push(where('published', '==', publishedReviews));
    }
  }

  if (style === 'inline') {
    constraints.push(where('containsReview', '==', true));
  }

  const {
    data: reviewsData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = Firebase<BookReview & { userData: UserProfile }>().fetchCollectionDocsByInfiniteQuery({
    path: 'book_reviews',
    constraints,
    limitNum: style === 'full' ? FULL_REVIEW_LIMIT : INLINE_REVIEW_LIMIT,
    additionalQuery,
    reactQueryOptions: {
      enabled: type === 'book' || (type === 'user' && Boolean(user_id)),
      getNextPageParam: (lastPage: InfiniteQueryResponse<BookReview>, pages: InfiniteQueryResponse<BookReview>[]) =>
        lastPage?.hasMore ? lastPage?.nextCursor : null,
    },
  });

  useEffect(() => {
    let mounted = true;

    const getLikedReviews = async () => {
      const _likedReviewsByPage = [...likedReviewsByPage];

      if (!reviewsData?.pages?.length) {
        setLikedReviewsByPage([]);
        return;
      }

      for (let i = 0; i < reviewsData.pages.length; i++) {
        if (!_likedReviewsByPage[i]) {
          _likedReviewsByPage[i] = [];
        }

        // Get IDs of reviews displayed in the list
        const reviewIds = reviewsData.pages[i]?.data?.map((review) => review.id);

        if (!reviewIds?.length) continue;

        // Get likes by the user for the reviews in the list
        const q = query(
          collectionGroup(db, 'book_review_likes'),
          where('review_id', 'in', reviewIds),
          where('user_id', '==', uid)
        );
        const querySnapshot = await getDocs(q);

        const userLikedReviewIds = querySnapshot.docs.map((doc) => doc.ref.parent.parent?.id);

        if (userLikedReviewIds.length) {
          _likedReviewsByPage[i] = userLikedReviewIds as string[];
        }
      }

      if (!mounted) return;

      setLikedReviewsByPage(_likedReviewsByPage);
    };
    if (!uid || !reviewsData) return;
    getLikedReviews();

    return () => {
      mounted = false;
    };
  }, [reviewsData, uid]);

  const reviews = useMemo(() => {
    const flattenedLikedReviews = likedReviewsByPage?.flat();
    return reviewsData?.pages
      ?.map((page) => page?.data)
      .flat()
      .filter(Boolean)
      .map((item) =>
        flattenedLikedReviews?.includes(item?.id as string) ? { ...item, liked: true } : item
      ) as (BookReview & { userData: UserProfile })[];
  }, [reviewsData, likedReviewsByPage]);

  const handleRefetchReviews = () => {
    refetch();
  };

  function renderRating() {
    if (type === 'user') return null;

    const { handleAuthAction } = useAuthenticatedAction({
      actionToCall: (rating: number) => {
        dispatch({
          type: ActionType.ADD_MODAL_DATA,
          payload: {
            persist: true,
            large: false,
            component: (
              <ReviewEditor title_id={title?.id} initialStars={rating} refetchReviews={handleRefetchReviews} />
            ),
          },
        });
      },
    });

    const handleRatingChange = (rating: number) => {
      handleAuthAction(
        {
          title: 'Login To Leave A Review',
          message:
            'You will need an account to write a review. Please login or create an account with one of the methods below.',
          component: <SessionForm />,
        },
        [rating]
      );
    };

    return (
      <div className={styles['summary-container']}>
        {!reviews?.length && (
          <>
            <div className={styles['top-content']}>
              <div className={styles['top-content-icon']}>
                <OpenBookIcon />
              </div>
              <div>
                <p className={styles['top-content-header']}>Be the first one to review</p>
                <p className={styles['top-content-text']}>Review the book today!</p>
              </div>
            </div>
            <div className={styles['divider']} />
          </>
        )}
        <div className={styles['bottom-content']}>
          <div className={styles['heading']}>Rate the book</div>
          <div className={styles['stars']}>
            <StarRating maxStars={5} onRatingChange={handleRatingChange} emptyColor={COLORS.lighterGrey} />
          </div>
        </div>
      </div>
    );
  }

  function renderTags() {
    if (!title?.top_review_emotion_tags?.length && !title?.top_review_tags?.length) return null;

    return (
      <div className={styles['tag-container']}>
        <div className={styles['text-tag']}>What people are saying about this book</div>
        <div className={styles['pill-container']}>
          {title?.top_review_emotion_tags?.slice(0, 4)?.map((t) => {
            return (
              <div className={styles['pill']}>
                <Pill text={t} style='secondary' />
              </div>
            );
          })}
          {title?.top_review_tags?.slice(0, 4)?.map((t) => {
            return (
              <div className={styles['pill']}>
                <Pill text={t} style='secondary' />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function renderReviewSummary() {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
      setIsTruncated(!isTruncated);
    };

    if (!title?.reviewSummary) return null;

    return (
      <Box m={1}>
        <Flex direction='row' align='flex-start' mb={0.5}>
          <PangoAvatar customSize={40} />
          <Box ml={0.5}>
            <Heading as='h3' size='base'>
              What readers are saying about this book
            </Heading>
            <Flex direction='row' align='flex-end'>
              <AiIcon size={20} strokeColor={COLORS.copy} />
              <Text color='copy' size='small'>
                Summarized by{' '}
                <Link href={Links.heyPango.index.pathname}>
                  <Text as='span' color='copy' size='small' link underline>
                    Pango AI
                  </Text>
                </Link>
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Box className={isTruncated ? styles['blurred-text'] : ''}>
          <Text size='base' copy color='copy'>
            {isTruncated ? `${title.reviewSummary.slice(0, 300)}...` : title.reviewSummary}
          </Text>
        </Box>
        <Button
          aria-expanded={!isTruncated}
          onPress={toggleTruncate}
          className={styles['read-more-toggle']}
          size='base'
          text={isTruncated ? 'Read more' : 'Read less'}
          style='clear'
          icon={
            isTruncated ? (
              <ChevronIcon direction='down' fillColor={COLORS.primary} />
            ) : (
              <ChevronIcon direction='up' fillColor={COLORS.primary} />
            )
          }
          iconPosition='right'
        />
      </Box>
    );
  }

  function renderSummaryOrTags() {
    if (title?.reviewSummary) return renderReviewSummary();
    return renderTags();
  }

  function renderTabs() {
    if (!IS_OWNER) return null;

    return (
      <div className={styles['tabs-container']}>
        <div
          className={classNames(styles['tabs-item'], { [styles['tabs-item--active']]: publishedReviews })}
          onClick={() => setPublishedReviews(true)}
        >
          <span className={classNames(styles['tabs-text'], { [styles['tabs-text--active']]: publishedReviews })}>
            Published
          </span>
        </div>
        <div
          className={classNames(styles['tabs-item'], { [styles['tabs-item--active']]: !publishedReviews })}
          onClick={() => setPublishedReviews(false)}
        >
          <span className={classNames(styles['tabs-text'], { [styles['tabs-text--active']]: !publishedReviews })}>
            Drafts
          </span>
        </div>
      </div>
    );
  }

  function renderRatingSummary() {
    if (type === 'user') return null;
    if (!title?.avg_rating || !title.num_reviews) return null;

    return (
      <div className={styles['summary-container']}>
        <div className={styles['top-content']}>
          <div className={styles['avg-rating-container']}>
            <div className={styles['avg-title']}>{title?.avg_rating.toFixed(2)}</div>
            <div className={styles['star-rating']}>
              <StarRating size={17} maxStars={5} initialRating={title?.avg_rating} disabled />
            </div>
            <div className={styles['text']}>
              {title?.num_reviews} {pluralize('Rating', title?.num_reviews)}
            </div>
          </div>
          <div className={styles['ratings-graph']}>
            {title?.rating_counts &&
              Object.keys(title?.rating_counts)
                .sort((a: any, b: any) => b - a)
                .map((key, idx) => {
                  return (
                    <div className={styles['ratings-graph-item']}>
                      <p className={styles['ratings-graph-text']}>{key}</p>
                      <PercentBar percent={(title?.rating_counts[key] / title?.num_reviews) * 100} />
                    </div>
                  );
                })}
          </div>
        </div>

        {renderSummaryOrTags()}
      </div>
    );
  }

  function renderReviews() {
    if (!reviews && style === 'full') {
      return (
        <div className={styles['spinner']}>
          <Spinner />
        </div>
      );
    }

    if (!reviews?.length && IS_OWNER) {
      return <div className={styles['reviews-container']}>{renderTabs()}</div>;
    }

    if (!reviews?.length) return null;

    return (
      <div className={styles['reviews-container']}>
        {renderTabs()}
        {reviews?.map((r) => {
          return (
            <div key={r.id}>
              <BookReviewTile review={r} type={type} />
              <div className={styles['divider']} />
            </div>
          );
        })}
        <div className={styles.seeMore}>{renderSeeMoreReviews()}</div>
      </div>
    );
  }

  function renderSeeMoreReviews() {
    if (type === 'book' && (!title?.num_reviews || title?.num_reviews <= INLINE_REVIEW_LIMIT)) return null;

    const handlePressSeeAll = () => {
      dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          title: 'All Book Reviews',
          large: true,
          component: (
            <>
              <Spacer />
              <BookReviews type='book' style='full' title={title} key={`book-reviews-full-${title.id}`} />
            </>
          ),
        },
      });
    };

    const handlePressSeeMore = () => {
      fetchNextPage();
    };

    if (style === 'inline') {
      return (
        <div>
          <Button text={`View all ${title?.num_reviews} reviews`} style='secondary' onPress={handlePressSeeAll} />
        </div>
      );
    }

    if (!hasNextPage) return null;

    return (
      <div>
        <Button
          text={`Show more`}
          style='secondary'
          onPress={handlePressSeeMore}
          loading={isFetchingNextPage}
          disabled={isFetchingNextPage}
        />
      </div>
    );
  }

  if (!title && type === 'book') return null;
  if (!user_id && type === 'user') return null;

  return (
    <div className={styles['container']} ref={innerRef}>
      {renderRating()}
      {renderRatingSummary()}
      {renderReviews()}
    </div>
  );
}
