import classNames from 'classnames';
import React from 'react';
import styles from '../styles/components/percent-bar.module.scss'


export default function PercentBar({ percent }) {

  const filledStyle = {
    width: `${percent}%`,

  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.bar, {
        [styles['filled-bar']]: percent === 100,
      }
      )}
        style={filledStyle}
      />
    </div>
  );
}

