import { where } from 'firebase/firestore';
import { useEffect, useReducer, useRef } from 'react';
import { BookReview, BookReviewCudProps, BookReviewState, Title } from 'types';
import Firebase from '@/clients/firebase/firebase-react-query';
import useDebounce from '~/lib/hooks/use-debounce';
import { useGlobalState } from '~/state';
import { v4 as uuid } from 'uuid';
import { backendFunction } from '~/clients/firebase/client';
import Spinner from './spinner';
import { images, mapPresetFilter, mapQueryBy, mapSortBy } from 'sdk';
import { Typesense } from 'sdk/src/clients/typesense/react-query';
import Image from 'next/image';
import styles from '../styles/components/review-editor.module.scss';
import Spacer from './spacer';
import StarRating from './star-rating';
import Input from './form/input';
import classNames from 'classnames';
import Button from './button';
import TextArea from './form/text-area';
import PillSelect from './pill-select';
import CloseCircle from '~/assets/svg/icons/close-circle';
import COLORS from '~/lib/helpers/color-helper';
import PlusCircle from '~/assets/svg/icons/plus-circle';
import { useSpring, animated } from '@react-spring/web';
import { ActionType } from '~/state/types';

type State = {
  review: BookReviewState;
  loadingSaveDraft: boolean;
  loadingSubmitReview: boolean;
  loadingDeleteReview: boolean;
  success: boolean;
  isAddingTag: boolean;
  isAddingEmotion: boolean;
  newTag: string;
  newEmotion: string;
  showSaveDraftConfirmation: boolean;
  showDeleteReviewConfirmation: boolean;
};

const initialState: State = {
  review: {
    rating: 0,
    title_id: '',
    id: '',
    review: '',
    tags: [],
    emotionTags: [],
  },
  loadingSaveDraft: false,
  loadingSubmitReview: false,
  loadingDeleteReview: false,
  success: false,
  isAddingTag: false,
  isAddingEmotion: false,
  newTag: '',
  newEmotion: '',
  showSaveDraftConfirmation: false,
  showDeleteReviewConfirmation: false,
};

type Action =
  | { type: 'SET_REVIEW'; payload: BookReviewState; }
  | { type: 'UPDATE_REVIEW'; payload: Partial<BookReviewState>; }
  | { type: 'SET_SUCCESS'; payload: boolean; }
  | { type: 'SET_LOADING_SAVE_DRAFT'; payload: boolean; }
  | { type: 'SET_LOADING_SUBMIT_REVIEW'; payload: boolean; }
  | { type: 'SET_LOADING_DELETE_REVIEW'; payload: boolean; }
  | { type: 'SET_NEW_TAG'; payload: string; }
  | { type: 'SET_NEW_EMOTION'; payload: string; }
  | { type: 'SET_IS_ADDING_EMOTION'; payload: boolean; }
  | { type: 'SET_IS_ADDING_TAG'; payload: boolean; }
  | { type: 'SET_SHOW_SAVE_DRAFT_CONFIRMATION'; payload: boolean; }
  | { type: 'SET_SHOW_DELETE_REVIEW_CONFIRMATION'; payload: boolean; };

// Our reducer function to handle state updates
function reviewReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_REVIEW':
      return { ...state, review: action.payload };
    case 'UPDATE_REVIEW':
      return { ...state, review: { ...state.review, ...action.payload } };
    case 'SET_SUCCESS':
      return { ...state, success: action.payload };
    case 'SET_LOADING_SAVE_DRAFT':
      return { ...state, loadingSaveDraft: action.payload };
    case 'SET_LOADING_SUBMIT_REVIEW':
      return { ...state, loadingSubmitReview: action.payload };
    case 'SET_LOADING_DELETE_REVIEW':
      return { ...state, loadingDeleteReview: action.payload };
    case 'SET_NEW_TAG':
      return { ...state, newTag: action.payload };
    case 'SET_NEW_EMOTION':
      return { ...state, newEmotion: action.payload };
    case 'SET_IS_ADDING_TAG':
      return { ...state, isAddingTag: action.payload };
    case 'SET_IS_ADDING_EMOTION':
      return { ...state, isAddingEmotion: action.payload };
    case 'SET_SHOW_SAVE_DRAFT_CONFIRMATION':
      return { ...state, showSaveDraftConfirmation: action.payload };
    case 'SET_SHOW_DELETE_REVIEW_CONFIRMATION':
      return { ...state, showDeleteReviewConfirmation: action.payload };
    default:
      return state;
  }
}

interface ReviewEditorProps {
  initialStars?: number;
  initialReview?: BookReview;
  title_id: string;
  refetchReviews?: () => void;
}
export default function ReviewEditor({ initialStars, initialReview, title_id, refetchReviews }: ReviewEditorProps) {
  const { state: globalState, dispatch: globalDispatch } = useGlobalState();
  const uid = globalState?.user?.data?.uid;
  const hasExisitingReviewBeenSet = useRef(false);

  const [state, dispatch] = useReducer(reviewReducer, initialState);

  const debouncedTagSearchQuery = useDebounce(state.newTag, 250);

  const IS_REVIEW_UPDATE = Boolean(initialReview) || Boolean(hasExisitingReviewBeenSet?.current);

  const { data: tagSearchResults } = Typesense<{ tag: string; id: string; }>().search({
    props: {
      index: 'hashtags',
      params: {
        q: debouncedTagSearchQuery,
        query_by: mapQueryBy('hashtags'),
        sort_by: mapSortBy('hashtags'),
        limit_hits: 2,
        page: 1,
        per_page: 2,
        filter_by: mapPresetFilter('hashtags'),
      },
      options: {},
    },
    reactQueryOptions: {
      enabled: Boolean(debouncedTagSearchQuery),
    },
  });

  const { data: title } = Firebase<Title>().fetchCollectionDocByPath(title_id, 'titles', {
    enabled: Boolean(title_id),
  });

  const { data: currentReviews, refetch: refetchCurrentReviews } = Firebase<BookReview>().fetchCollectionDocsByQuery(
    'book_reviews',
    [where('title_id', '==', title_id), where('user', '==', uid)],
    {
      enabled: Boolean(title_id) && Boolean(uid),
    }
  );

  const { data: helperData } = Firebase<{ emotion_tags: string[]; }>().fetchCollectionDocByPath(
    'book_reviews',
    'admin',
    {
      enabled: true,
    }
  );

  const animPropsEmotionTags = useSpring({
    opacity: state.isAddingEmotion ? 1 : 0,
    height: state.isAddingEmotion ? '5rem' : '0rem', // adjust as needed
  });

  const animPropsTags = useSpring({
    opacity: state.isAddingTag ? 1 : 0,
    height: state.isAddingTag ? '4rem' : '0rem', // adjust as needed
  });

  const animTagDropdown = useSpring({
    height: state.newTag?.length ? '8rem' : '0rem', // adjust as needed
    opacity: state.newTag?.length ? 1 : 0,
  });

  useEffect(() => {
    if (hasExisitingReviewBeenSet.current) return;
    if (initialReview) {
      dispatch({
        type: 'SET_REVIEW',
        payload: {
          id: initialReview.id,
          title_id: initialReview.title_id,
          rating: initialReview.rating,
          review: initialReview.review,
          tags: initialReview.tags,
          emotionTags: initialReview.emotionTags,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_REVIEW',
        payload: {
          rating: initialStars || 0,
          title_id,
          id: uuid(),
        },
      });
    }
  }, [initialReview, initialStars, title_id]);

  useEffect(() => {
    if (!currentReviews?.length || hasExisitingReviewBeenSet.current) return;
    const [currentReview] = currentReviews;
    hasExisitingReviewBeenSet.current = true;
    dispatch({
      type: 'SET_REVIEW',
      payload: {
        id: currentReview.id,
        title_id: currentReview.title_id,
        rating: currentReview.rating,
        review: currentReview.review,
        tags: currentReview.tags,
        emotionTags: currentReview.emotionTags,
      },
    });
  }, [currentReviews]);

  const handleSubmitReview = async ({ draft, type }: { draft: boolean; type: BookReviewCudProps['type']; }) => {
    if (type === 'delete') dispatch({ type: 'SET_LOADING_DELETE_REVIEW', payload: true });
    else
      draft
        ? dispatch({ type: 'SET_LOADING_SAVE_DRAFT', payload: true })
        : dispatch({ type: 'SET_LOADING_SUBMIT_REVIEW', payload: true });

    try {
      await backendFunction<void, BookReviewCudProps>('book_reviews-cud', {
        book_review: state.review,
        draft,
        type: type,
        platform: 'web',
      });
      globalDispatch({ type: ActionType.FLUSH_MODAL_DATA });
      if (type === 'delete') {
        refetchCurrentReviews(); // If they click into this review again it wont think they already wrote one based on the cached fetch
        globalDispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: 'Success',
            message: 'Your review has been deleted.',
          },
        });
      } else if (draft) {
        globalDispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: 'Success',
            message:
              'Your draft has been saved. You can manage all your reviews in the "My Reviews" section on your account.',
          },
        });
      } else if (type === 'update') {
        globalDispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: 'Success',
            message: 'Your review has been updated!',
          },
        });
      } else {
        globalDispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: 'Thanks For Your Review!',
            message: 'You can always edit your review later',
          },
        });
      }
      refetchReviews && refetchReviews();
    } catch (e: any) {
      alert(`Error: ${e.message}`);
    }
    draft
      ? dispatch({ type: 'SET_LOADING_SAVE_DRAFT', payload: false })
      : dispatch({ type: 'SET_LOADING_SUBMIT_REVIEW', payload: false });
  };

  const handleSaveDraftConfirmation = () => {
    dispatch({ type: 'SET_SHOW_SAVE_DRAFT_CONFIRMATION', payload: !state.showSaveDraftConfirmation });
  };

  const handleDeleteReviewConfirmation = () => {
    dispatch({ type: 'SET_SHOW_DELETE_REVIEW_CONFIRMATION', payload: !state.showDeleteReviewConfirmation });
  };

  const handleSaveDraft = () => {
    handleSubmitReview({ draft: true, type: IS_REVIEW_UPDATE ? 'update' : 'create' });
  };

  const handleDeleteReview = () => {
    handleSubmitReview({ draft: false, type: 'delete' });
  };

  const handleSaveChanges = () => {
    handleSubmitReview({ draft: false, type: IS_REVIEW_UPDATE ? 'update' : 'create' });
  };

  const SHOULD_SHOW_LOADER = !title || !state.review || !helperData;

  if (SHOULD_SHOW_LOADER) {
    // TODO test and improve loader
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  function renderHeader(text: string) {
    return (
      <div>
        <div className={styles['header']}>{text}</div>
      </div>
    );
  }

  function renderTitle() {
    const IMG_URL = title?.thumb_url || images(title?.selected_listing?.photo_path as string).book.thumb;
    const objectFit = title?.thumb_url ? 'contain' : 'cover';

    if (!IMG_URL) return null;

    return (
      <div className={styles['title']}>
        <div className={styles['image']}>
          <Image
            alt={title.title}
            src={IMG_URL}
            className={classNames(styles['image'], {
              [styles['image-cover']]: objectFit === 'cover',
              [styles['image-contain']]: objectFit === 'contain',
            })}
            height={60}
            width={60}
            unoptimized
          />
        </div>
        <div className={styles['left-space']}>
          <div className={classNames(styles['text-bold'], styles['lines1'])}>{title?.title}</div>
          <Spacer />
          <div className={classNames(styles['text-light-small'], styles['lines1'])}>
            By: <span className={styles['text-small']}>{title?.authors?.[0]?.name}</span>
          </div>
        </div>
      </div>
    );
  }

  function renderRating() {
    return (
      <div>
        <div className={styles['text-label']}>Select a Rating</div>
        <div className={styles['stars-position']}>
          <div className={styles['stars']}>
            <StarRating
              maxStars={5}
              initialRating={state.review.rating || 0}
              onRatingChange={(rating: number) => dispatch({ type: 'UPDATE_REVIEW', payload: { rating } })}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderReview() {
    return (
      <div className={styles['review-container']}>
        <div className={styles['label-container']}>
          <div className={styles['text-label']}>Write a Review</div>
          <div className={styles['text-light-small']}>Optional</div>
        </div>
        <TextArea
          placeholder='Type your review here...'
          value={state.review.review}
          onChange={(e: any) => dispatch({ type: 'UPDATE_REVIEW', payload: { review: e.target.value } })}
        />
      </div>
    );
  }

  function renderTags() {
    return (
      <div className={styles['tagsContainer']}>
        <div className={styles['label-container']}>
          <div className={styles['text-label']}>What tags best represent this book?</div>
          <div className={styles['text-light-small']}>Optional</div>
        </div>
        <div className={styles['tags']}>
          <PillSelect
            options={
              title?.hashtags
                ?.slice(0, 6)
                ?.map((t) => (!t?.startsWith('#') ? `#${t}` : t))
                .concat(
                  state.review.tags
                    .map((t) => (title?.hashtags?.slice(0, 6)?.includes(t) ? null : `#${t}`))
                    .filter(Boolean) as string[]
                ) as string[]
            }
            selected={state.review.tags?.map((t) => (!t?.startsWith('#') ? `#${t}` : t)) as string[]}
            onChange={(tags: string[]) =>
              dispatch({ type: 'UPDATE_REVIEW', payload: { tags: tags.map((t) => t.replace('#', '')) } })
            }
            style='secondary'
          />
        </div>
        <div
          onClick={() => {
            dispatch({ type: 'SET_NEW_TAG', payload: '' });
            dispatch({ type: 'SET_IS_ADDING_TAG', payload: !state.isAddingTag });
          }}
          className={styles['add-tags']}
        >
          {state.isAddingTag ? (
            <>
              <CloseCircle strokeColor={COLORS.primary} />
              <div className={styles['text-toggle']}>Hide Field</div>
            </>
          ) : (
            <>
              <PlusCircle strokeColor={COLORS.primary} />
              <div className={styles['text-toggle']}>Add Tags</div>
            </>
          )}
        </div>
        <animated.div style={animPropsTags}>
          <Spacer />
          <Input
            placeholder='Enter new tag'
            onChange={(e: any) => dispatch({ type: 'SET_NEW_TAG', payload: e.target.value })}
            value={state.newTag}
          />
        </animated.div>
        <animated.div style={animTagDropdown}>
          <div className={styles['search-results-container']}>
            <div
              key={state.newTag}
              onClick={() => {
                dispatch({
                  type: 'UPDATE_REVIEW',
                  payload: { tags: [...state.review.tags, state.newTag?.replace('#', '')] },
                });
                dispatch({ type: 'SET_NEW_TAG', payload: '' });
              }}
              className={styles['tag-search-result']}
            >
              <div>{state.newTag}</div>
            </div>
            {tagSearchResults?.hits
              ?.filter((t) => t.document.id !== state.newTag)
              .map((tag) => (
                <div
                  key={tag.document.id}
                  onClick={() => {
                    dispatch({
                      type: 'UPDATE_REVIEW',
                      payload: { tags: [...state.review.tags, tag?.document.id?.replace('#', '')] },
                    });
                    dispatch({ type: 'SET_NEW_TAG', payload: '' });
                  }}
                  className={styles['tag-search-result']}
                >
                  <div className={styles['lines1']}>{tag.document.id}</div>
                </div>
              ))}
          </div>
        </animated.div>
      </div>
    );
  }

  function renderEmotionTags() {
    const handleSubmitTag = (e: any) => {
      e.preventDefault();
      state.newEmotion?.trim()?.length &&
        dispatch({ type: 'UPDATE_REVIEW', payload: { emotionTags: [...state.review.emotionTags, state.newEmotion] } });
      dispatch({ type: 'SET_NEW_EMOTION', payload: '' });
    };

    return (
      <div className={styles['tagsContainer']}>
        <div className={styles['label-container']}>
          <div className={styles['text-label']}>What does this book evoke in you?</div>
          <div className={styles['text-light-small']}>Optional</div>
        </div>
        <div className={styles['tags']}>
          <PillSelect
            options={
              helperData?.emotion_tags?.concat(
                state.review.emotionTags
                  ?.map((t) => (helperData?.emotion_tags?.includes(t) ? null : t))
                  .filter(Boolean) as string[]
              ) as string[]
            }
            selected={state.review.emotionTags as string[]}
            onChange={(emotionTags: string[]) => dispatch({ type: 'UPDATE_REVIEW', payload: { emotionTags } })}
            style='secondary'
          />
        </div>
        <div
          onClick={() => {
            dispatch({ type: 'SET_IS_ADDING_EMOTION', payload: !state.isAddingEmotion });
            dispatch({ type: 'SET_NEW_EMOTION', payload: '' });
          }}
          className={styles['add-tags']}
        >
          {state.isAddingEmotion ? (
            <>
              <CloseCircle strokeColor={COLORS.primary} />
              <div className={styles['text-toggle']}>Hide Field</div>
            </>
          ) : (
            <>
              <PlusCircle strokeColor={COLORS.primary} />
              <div className={styles['text-toggle']}>Add Keywords</div>
            </>
          )}
        </div>
        {state.isAddingEmotion && (
          <animated.div style={animPropsEmotionTags}>
            <Spacer size='small' />
            <form onSubmit={handleSubmitTag}>
              <Input
                message={state.newEmotion ? 'Press Enter to add keyword' : ''}
                placeholder='Enter new keyword'
                onChange={(e: any) => dispatch({ type: 'SET_NEW_EMOTION', payload: e.target.value })}
                value={state.newEmotion}
              />
            </form>
          </animated.div>
        )}
      </div>
    );
  }

  function renderButtons() {
    return (
      <div className={styles['buttons-container']}>
        <div className={styles['button']}>
          <Button
            text='Save Draft'
            style='secondary'
            onPress={handleSaveDraftConfirmation}
            className='secondary'
            loading={state.loadingSaveDraft}
            disabled={state.loadingSaveDraft || state.loadingSubmitReview || state.loadingDeleteReview}
          />
        </div>

        {IS_REVIEW_UPDATE && (
          <div className={styles['button']}>
            <Button
              text={'Delete Review'}
              onPress={handleDeleteReviewConfirmation}
              style='danger'
              loading={state.loadingDeleteReview}
              disabled={state.loadingSaveDraft || state.loadingSubmitReview || state.loadingDeleteReview}
            />
          </div>
        )}

        <div className={classNames(styles['button'])}>
          <Button
            text={IS_REVIEW_UPDATE ? 'Save Changes' : 'Submit'}
            onPress={handleSaveChanges}
            style='primary'
            loading={state.loadingSubmitReview}
            disabled={state.loadingSaveDraft || state.loadingSubmitReview || state.loadingDeleteReview}
          />
        </div>
      </div>
    );
  }

  function renderSaveDraftConfirmation() {
    return (
      <div className={styles['container']}>
        {renderHeader('Save Draft')}
        <Spacer />
        <Spacer />
        <div className={classNames(styles['copy'], styles['center-text'])}>
          Are you sure you want to close the review and save it as a draft? You can always edit your review later.
        </div>
        <Spacer />
        <Spacer />
        <div className={styles['buttons-container']}>
          <div className={styles['button']}>
            <Button
              text='Continue Review'
              style='secondary'
              onPress={handleSaveDraftConfirmation}
              className='secondary'
              disabled={state.loadingSaveDraft || state.loadingSubmitReview || state.loadingDeleteReview}
            />
          </div>
          <div className={classNames(styles['button'])}>
            <Button
              text='Close & Save'
              onPress={handleSaveDraft}
              style='primary'
              loading={state.loadingSaveDraft}
              disabled={state.loadingSaveDraft || state.loadingSubmitReview || state.loadingDeleteReview}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderDeleteReviewConfirmation() {
    return (
      <div className={styles['container']}>
        {renderHeader('Delete Review')}
        <Spacer />
        <Spacer />
        <div className={classNames(styles['copy'], styles['center-text'])}>
          Are you sure you want to delete your review? This action cannot be undone.
        </div>
        <Spacer />
        <Spacer />
        <div className={styles['buttons-container']}>
          <div className={styles['button']}>
            <Button
              text='Continue Review'
              style='secondary'
              onPress={handleDeleteReviewConfirmation}
              className='secondary'
              disabled={state.loadingSaveDraft || state.loadingSubmitReview || state.loadingDeleteReview}
            />
          </div>
          <div className={classNames(styles['button'])}>
            <Button
              text='Close & Delete'
              onPress={handleDeleteReview}
              style='danger'
              loading={state.loadingDeleteReview}
              disabled={state.loadingSaveDraft || state.loadingSubmitReview || state.loadingDeleteReview}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderReviewEditor() {
    return (
      <div className={styles['container']}>
        {renderHeader('Rate the book')}
        <Spacer />
        <Spacer />
        {renderTitle()}
        <Spacer />
        <Spacer />
        {renderRating()}
        <Spacer />
        <Spacer />
        {renderReview()}
        <Spacer />
        {renderTags()}
        <Spacer />
        {renderEmotionTags()}
        <Spacer />
        <Spacer />
        {renderButtons()}
        <Spacer />
      </div>
    );
  }

  if (state.showSaveDraftConfirmation) {
    return renderSaveDraftConfirmation();
  }

  if (state.showDeleteReviewConfirmation) {
    return renderDeleteReviewConfirmation();
  }

  return renderReviewEditor();
}
