import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { formatDate, images, pluralize, truncate } from 'sdk';
import { BookReview, Title, UserProfile } from 'types';
import EditFilled2 from '~/assets/svg/icons/edit-filled2';
import ThumbsUp from '~/assets/svg/icons/thumbs-up';
import VertDots from '~/assets/svg/icons/vert-dots';
import { db } from '~/clients/firebase/client';
import COLORS from '~/lib/helpers/color-helper';
import Links from '~/lib/helpers/link-helper';
import useAuthenticatedAction from '~/lib/hooks/use-authenticated-action';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import styles from '../../styles/components/tiles/book-review.module.scss';
import ListItem from '../list';
import ReviewEditor from '../review-editor';
import SessionForm from '../session-form';
import Spacer from '../spacer';
import StarRating from '../star-rating';
import Text from '../typography/text';

function EditModal({ review }: { review: BookReview }) {
  const { dispatch } = useGlobalState();
  function handleEditReview() {
    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        component: <ReviewEditor initialReview={review} title_id={review.title_id} />,
      },
    });
  }

  return (
    <div className={styles['edit-modal']}>
      <div className={styles['title']}>Actions</div>

      <ListItem showHover onPress={handleEditReview}>
        <ListItem.Icon icon={<EditFilled2 height={26} width={26} />} />
        <ListItem.Content>
          <ListItem.Title>Edit Review</ListItem.Title>
        </ListItem.Content>
      </ListItem>
    </div>
  );
}

export default function BookReviewTile({
  review,
  type,
}: {
  review: BookReview & { userData: UserProfile; titleData?: Title };
  type: 'user' | 'book';
}) {
  const [locallyLiked, setLocallyLiked] = useState<boolean>(null!);
  const [localLikesCount, setLocalLikesCount] = useState<number>(review.likes || 0);
  const [isTruncated, setIsTruncated] = useState(true);

  const { state, dispatch } = useGlobalState();
  const uid = state?.user?.data?.uid;

  const IS_LIKED = locallyLiked === null ? review.liked : locallyLiked;

  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: () => {
      const docRef = doc(db, `book_reviews/${review.id}/book_review_likes/${uid}`);
      if (IS_LIKED) {
        setLocallyLiked(false);
        setLocalLikesCount((prev) => (prev > 0 ? prev - 1 : prev));
        deleteDoc(docRef);
      } else {
        setLocallyLiked(true);
        setLocalLikesCount((prev) => prev + 1);
        setDoc(docRef, {
          user_id: uid,
          review_id: review.id,
          timestamp: new Date(),
        });
      }
    },
  });

  const handleLike = () => {
    handleAuthAction({
      title: 'Login To Like This Review',
      message:
        'You will need an account to like a review. Please login or create an account with one of the methods below.',
      component: <SessionForm />,
    });
  };

  function renderTopContent() {
    return (
      <>
        <Spacer />
        <div className={styles['top-content']}>
          <div className={styles['star-rating']}>
            <StarRating maxStars={5} initialRating={review.rating} disabled />
          </div>
          <div>
            {review.timestamp.toDate && (
              <div className={styles['date-text']}>
                {formatDate({
                  date: review.timestamp.toDate(),
                  format: 'MM D YYYY',
                })}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  function renderReview() {
    if (!review.review) return null;

    const handlePress = () => {
      setIsTruncated(!isTruncated);
    };

    const truncatedText = truncate(review.review, 300);

    return (
      <>
        <Spacer />
        <div className={styles['review-text']}>{isTruncated ? truncatedText : review.review}</div>
        {review.review?.trim() !== truncatedText?.trim() && (
          <>
            {isTruncated ? (
              <div className={styles['click-text']} onClick={handlePress}>
                <div color='primary'>{'Read more'}</div>
              </div>
            ) : (
              <div className={styles['click-text']} onClick={handlePress}>
                <div color='primary'>{'Read less'}</div>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  function renderLikes() {
    return (
      <>
        <Spacer />
        <div className={styles['thumb']} onClick={handleLike}>
          <ThumbsUp fillColor={IS_LIKED ? COLORS.primary : COLORS.copy} />
          {localLikesCount ? <span className={styles['like-count']}>{localLikesCount}</span> : null}
        </div>
      </>
    );
  }

  function renderUserOrTitle() {
    if (type === 'book' && !review?.userData) return <div className={styles['user']} />;

    const IMG_URL =
      type === 'book'
        ? (images(review?.userData?.photo_path).user.profileImage as string)
        : review?.titleData?.thumb_url || images(review?.titleData?.selected_listing?.photo_path as string).book.thumb;

    const objectFit = type === 'user' && review.titleData?.thumb_url ? 'contain' : 'cover';

    const title = type === 'book' ? review?.userData?.name : review?.titleData?.title;

    const link = type === 'book' ? Links.bookstore(review?.userData).show : Links.title(review.titleData).show;

    function renderLowerText() {
      if (type === 'book') {
        return (
          <Text className={styles['subtext']} copy>
            {review?.userData?.book_reviews || 1} book {pluralize('review', review?.userData?.book_reviews || 1)}
          </Text>
        );
      }
      return (
        <div className={styles['subtext']}>
          By:{' '}
          <Link
            href={Links.author.slug({ slug: review.titleData?.author_ids?.[0] }).pathname}
            className={styles['author']}
          >
            {review?.titleData?.authors?.[0]?.name}
          </Link>
        </div>
      );
    }

    function renderDotsMenu() {
      if (type !== 'user' || uid !== review.user) return null;

      function handleClickMenu() {
        dispatch({
          type: ActionType.ADD_MODAL_DATA,
          payload: {
            component: <EditModal review={review} />,
          },
        });
      }

      return (
        <div className={styles['dots-menu']} onClick={handleClickMenu}>
          <VertDots />
        </div>
      );
    }

    return (
      <div className={styles['user-container']}>
        <div className={styles['user']}>
          <Link href={link} legacyBehavior>
            {IMG_URL ? (
              <Image
                alt='profile image'
                src={IMG_URL}
                className={styles['avatar']}
                width={48}
                height={48}
                objectFit={objectFit}
                unoptimized
              />
            ) : (
              <div className={styles['avatar']} />
            )}
          </Link>
          <div className={styles['user-details']}>
            <Link href={link} legacyBehavior>
              <Text className={styles['name']} copy>
                {title}
              </Text>
            </Link>
            {renderLowerText()}
          </div>
        </div>
        {renderDotsMenu()}
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      {renderUserOrTitle()}
      {renderTopContent()}
      {renderReview()}
      {renderLikes()}
    </div>
  );
}
