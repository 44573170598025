import styles from '../styles/components/pill-select.module.scss';
import Pill from './pill';

interface PillSelectProps {
    options: string[];
    selected: string[];
    onChange: (selected: string[]) => void;
    style?: 'primary' | 'secondary' | 'light';
}
export default function PillSelect({
    options,
    selected,
    onChange,
    style = 'primary',
}: PillSelectProps) {

    return (
        <div className={styles.container}>
            {options?.map((option) => {
                const isSelected = selected.includes(option);
                return (
                    <div key={option} className={styles.pill}>
                        <Pill
                            style={style}
                            text={option}
                            outline={isSelected}
                            textColor={isSelected ? 'primary' : 'copyDark'}
                            onClick={() => {
                                if (isSelected) {
                                    onChange(selected.filter((s) => s !== option));
                                } else {
                                    onChange([...selected, option]);
                                }
                            }}
                            active={isSelected}
                        />
                    </div>
                );
            })}
        </div>
    )
}
